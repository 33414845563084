import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DataTableModule } from "angular-6-datatable-cc";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxKeyboardEventsModule } from 'ngx-keyboard-events';
import { SharedModuleModule } from './shared-module/shared-module.module';
import { DataTable } from 'angular-6-datatable-cc/src/DataTable';
import { DemoComponent } from './demo/demo.component';




@NgModule({
    declarations: [
        AppComponent,
        DemoComponent


        // DataTable
    ],
    imports: [
        BrowserModule,
        SharedModuleModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,

        NgxBarcodeModule,
        NgxKeyboardEventsModule,
        DataTableModule,
    
    ],
    providers: [],
    bootstrap: [AppComponent]
})

export class AppModule { }
